<template>
  <div v-if="showed" class="template-vars">
    <div v-if="loading">Carregando variáveis...</div>
    <div v-else>
      <div class="m-t-n-sm m-b text-right">
        <u-btn icon="times" icon-type="fa" flat round rounded size="xs" class="" @click="showed = false" />
      </div>
      <ul>
        <li class="font-bold m-b title">Variáveis internas</li>
        <li v-for="v in varInternals">
          <a>
            <div class="font-12">{{v.name}}</div>
            <div class="font-10 text-blue-grey-6 m-t-xs">{{v.description}}</div>
          </a>
        </li>

        <li class="font-bold m-b title">Variáveis personalizadas</li>
        <li v-for="v in variablesCustom">
          <a>
            <div class="font-12">{{ '{' + v.name + '}' }}</div>
            <div class="font-10 text-blue-grey-6 m-t-xs">{{v.description}}</div>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {list} from '@/domain/variaveis/services'

export default {
  name: 'showAll',
  data () {
    return {
      showed: false,
      loading: false,
      internals: null,
      variables: null
    }
  },
  computed: {
    varInternals () {
      return this.variables.filter(v => v.internal)
    },
    variablesCustom () {
      return this.variables.filter(v => !v.internal)
    }
  },
  mounted () {
    this.load()
  },
  methods: {
    show () {
      this.showed = true
    },
    hide () {
      this.showed = false
    },
    load () {
      this.loading = true
      list(1000, 1, '') // TODO Implementar acima de 1.000 variáveis
        .then(response => {
          console.log(response.data)
          this.internals = response.data.internals
          this.variables = response.data.result
          this.loading = false
        })
        .catch(error => {
          this.loading = false
          this.alertApiError(error)
        })
    }
  }
}
</script>

<style scoped>

</style>
